import request from '../utils/request'

export function signIn(data) {
  return request({
    url: `/auth/login`,
    method: 'post',
    data
  })
}

export function signUp(data) {
  return request({
    url: `/auth/signUp`,
    method: 'post',
    data
  })
}

export function forgetPassword(data) {
  return request({
    url: `/auth/forgetPassword`,
    method: 'post',
    data
  })
}

export function reSendEmail(data) {
  return request({
    url: `/auth/reSendEmail`,
    method: 'post',
    data
  })
}

export function checkAccount(data) {
  return request({
    url: `/auth/checkAccount`,
    method: 'post',
    data
  })
}
